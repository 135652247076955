/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import "channels"

import 'bootstrap'
import 'jquery.scrollbar'
import 'select2'
import 'daterangepicker'
import 'chart.js'

// import 'src/cable'
import Utility from 'src/utility'
export { Utility }

import Charts from 'src/charts'
export { Charts }

import Dashboard from 'src/dashboard'
export { Dashboard }

import FormSubmissions from 'src/form_submissions'
export { FormSubmissions }

import Modal from 'src/modal'
export { Modal }

import Profile from 'src/profiles'
export { Profile }

import Reports from 'src/reports'
export { Reports }

import trackingScripts from 'src/tracking_scripts'
export { trackingScripts }

import '../src/document_binders';

// ends javascript

// css
import 'stylesheets/application'

// images
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

Rails.start()
Turbolinks.start()
